import React, { useEffect } from 'react';

const Item = ({ title, children }) => (
  <p className="color-primary" style={{ lineHeight: '2em' }}>
    {title && (<>
      <strong>{title}</strong>
      <br />
    </>)}
    {children}
  </p>
);

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Amplisend - Terms of Use';
  });

  return (
    <section className="section sm section-blog2 mt-6">
      <div className="container">
        <div className="content-post">
          <h1>Terms of Use</h1>
          <hr />
          <Item>
            The following are the terms and conditions for use of the Edgility SMS Messaging systems including Amplisend. You, as account
            holders and users are subject to these terms and conditions, the Edgility Privacy Statement, disclaimers and notices (collectively
            known as "Terms of use") issued by Edgility.<br /><br />
            Use of or access to the Edgility messaging service, constitutes agreement to these terms of use.
          </Item>

          <Item title="Changes to or Termination of Accounts">
            Edgility may change any of these Terms of Use at anytime by giving notice to the account holder. This notice may be by way of email or a posting of the change on the Edgility website once a user is logged in to their account. Continued use of the Edgility
            account will amount to acceptance of these changes.<br /><br />
            Edgility may terminate any account holder's account if it believes any of these Terms of Use have been breached, or to protect
            Edgility property. Monies owing by the account holder will still be payable to Edgility.
          </Item>

          <Item title="Users Responsibilities">
            Account holders and/or users must keep their personal registration information (name, billing details and contact data) accurate,
            complete and up to date. Edgility reserves the right to verify this information from time to time and to terminate without notice any
            accounts found in non-compliance with this requirement.<br /><br />
            Account holder information may be accessed in accordance with the Edgility Privacy Principles.<br /><br />
            Use of Edgility’s Messaging systems is the account holder's responsibility and totally at the account holder's own risk.
            Edgility (including its employees and contractors) will not be liable for any loss or damage caused to the account holder, user or
            anyone else as a result of using the Edgility service. This includes but is not limited to loss or damage caused by loss or delay of
            message content or any loss caused by the negligence of Edgility, its employees or contractors.<br /><br />
            Account holders and users will be totally responsible for costs incurred from the use of their account, including costs incurred by
            others entrusted with the account holder's username and password.<br /><br />
            Therefore account holders and users must accept responsibility for all aspects of their Edgility account, including the actions of all
            persons in possession of the account holders username and password.<br /><br />
            The account holder or user indemnifies Edgility from all costs, liabilities, suits, actions or claims arising or in any other way connected
            with Edgility from the account holder's or user's use of their Edgility account, or any other person using the account holder's username
            and password.<br /><br />
            The account holder and user agrees not to transmit any material which violates State or Federal law, instructions, regulations or
            guidelines issued by regulatory authorities, relevant licenses and other codes of practice or transmit any material which is in
            contravention to any privacy or copyright rules or any other proprietary interest.<br /><br />
            The account holder and/or user agrees not to harass, stalk, abuse or threaten any other person through the use of Edgility
            messaging.<br /><br />
            The account holder agrees to be aware and abide by State and National regulation and law regarding the use of SMS as an
            advertising and communication media. The account holder, as a message originator, agrees to comply with:
            In Australia;<br /><br />
            1. The SPAM ACT 2003 - See the Practical guide for business; and<br />
            2. The Australian Communications Industry Forum (ACIF) Industry Code of Short Message Service (SMS) Guideline, ACIF
            document C580 December 2002.<br />
            In New Zealand;<br />
            1. The Unsolicited Electronic Messages Act 2007, issued by The Parliamentary Counsel Office of New Zealand.<br /><br />
            Further information on the SPAM ACT practical guide for business and the ACIF Industry code for SMS is outlined in the "SMS Guideline
            for commercial message originators" below.<br /><br />
            The account holder and/or user agrees not to interfere with the operation of the Edgility messaging system.
            Edgility directors maintain the right to inspect message content and take appropriate action on receiving complaints relating to
            message content via the Edgility service. We take abuse of people's privacy rights seriously. Edgility contacts all account holders by
            e-mail or phone for any matter relating to unsolicited, abusive, deceptive or misleading messaging that has been conducted
            through the Edgility service. Appropriate action may constitute the suspension or cancellation of an account pending investigation
            into any alleged abuse or misuse of the Edgility service for inappropriate or illegal use.<br /><br />
            The account holder and/or user agrees that all the contents of the Edgility Messaging service, including web-sites, pages, logos,
            hardware and promotional materials are protected by copyrights and trademarks and remain the property of Edgility and may not
            be copied for any reason.
          </Item>

          <Item title="SMS Guidelines for commercial message originators">
            1. The SPAM ACT or THE UNSOLICITED ELECTRONIC MESSAGES ACT. Edgility account holders agree to comply with the SPAM
            ACT or THE UNSOLICITED ELECTRONIC MESSAGES ACT. Further Edgility account holders agree to follow the guide produced
            by the ACA of SPAM ACT: a practical guide for business or The Unsolicited Electronic Messages Act 2007 which state;
            The three key steps you should follow are:<br />
            A. Consent<br />
            Only send commercial electronic messages with the addressee's consent - either express or inferred consent<br />
            B. Identify<br />
            Include clear and accurate information about the person or business that is responsible for sending the commercial electronic message.<br />
            C. Unsubscribe<br />
            Ensure that a functional unsubscribe facility is included in all your commercial electronic messages. Deal with unsubscribe requests promptly.<br />
            <br /><br />
            2. Edgility account holders agree to comply with ACIF Industry Guidelines for sending SMS.<br /><br />
            This guideline states:<br />
            a) Recognised Identifier - Message originators (the Edgility Account Holder) should include a Recognised Identifier in all
            their Marketing Messages. The Recognised Identifier should be in a form that reasonably enables a Recipient to identify or
            directly contact the Message Originator (the Edgility account holder).<br />
            b) Prohibition of Marketing Messages - Message originators should not send Marketing Messages to Recipients via SMS
            unless:<br />
            i. The Recipient has requested the Marketing Messages<br />
            ii. The Recipient has provided the Message Originator with prior consent to send such Marketing Messages; or<br />
            iii. The message Originator complies with the provisions of Clause 2.1 (a) or Clause 2.1 (c) of the National Privacy
            Principles, as contained in Schedule 3 of the Privacy Act 1998<br /><br />
            c) Recipients Rights to Opt Out - Edgility account holders sending marketing messages should implement appropriate
            means of allowing the recipient to notify the message originator not to send further Marketing Messages. The means
            should be:<br />
            i. Easy to use by the Recipients<br />
            ii. Minimise any inconvenience<br />
            iii. Be provided at a low cost to the recipient<br />
            d) Compliance with Opt out Notices - The message originator should comply as soon as practicable with any notice it
            receives under clause<br />
            c) above and not send further Marketing Messages unless and until the Recipient requests or
            consents to receiving further marketing messages.
          </Item>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditions;
