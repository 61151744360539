import React from 'react';
import PropTypes from 'prop-types';

const FAQItem = ({ title, children }) => (
  <div className="col-lg-6">
    <div className="card-faq">
      <h4 className="title">{title}</h4>
      <p className="desc">
        {children}
      </p>
    </div>
  </div>
);

FAQItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FAQItem;
