import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import useWindowDimensions from './inc/useWindowDimensions';

import DefaultHeader from './inc/DefaultHeader';

const Header = () => {
  const { width } = useWindowDimensions();
  const [state, setState] = useState({
    headerClass: null,
    navClass: null,
    homepage: false,
    mobileMenu: false,
  });
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const { pathname } = location;

    if (state.pathname === pathname) {
      return;
    }

    const newState = {
      ...state,
      homepage: false,
      navClass: 'is-dark',
      title: null,
      subTitle: null,
      header: null,
      subHeader: null,
      icon: null,
      link: null,
    };

    switch (pathname) {
      default:
        break;
      case '/':
        newState.headerClass = 'header has-style2 section';
        newState.homepage = true;

        newState.title = 'Send SMS quickly and reliably';
        newState.subTitle = `
          With Amplisend's easy to use APIs and quality network connections
          we're able to deliver SMS around the world affordably, quickly and reliably.
        `;
        newState.link = 'https://app.amplisend.com/register';
        newState.linkText = 'Get Started';
        newState.icon = true;
        break;
    }

    setState({
      ...newState,
      pathname,
    });
  }, [location, state, setState]);

  const toggleMenu = () => {
    setState({
      ...state,
      mobileMenu: !state.mobileMenu,
    });
  }

  const redirectTo = target => {
    setState({
      ...state,
      mobileMenu: false,
    });

    history.push(target);
  }

  const navigation = [
    ['Home', '/'],
    ['About', '/about'],
    ['Pricing', '/pricing'],
    ['Developers', '/developers'],
    ['Contact', '/contact'],
  ];

  const menuDisplay = (state.mobileMenu && width < 993) ? true : false;

  return (
    <header className={state.headerClass} style={{ overflow: menuDisplay ? 'inherit' : 'hidden', zIndex: menuDisplay ? 1000 : 1 }}>
      {state.homepage && <img className="section-particle top-0" src="./images/others/particle.svg" alt="" />}
      <nav className={clsx('navbar', state.navClass)}>
        <div className="container">
          <div className="flex">
            <Link to="/" className="navbar-brand flex vcenter">
              <img className="logo" src="./logo512.png" alt="Amplisend" />
            </Link>
            <ul className="navbar-menu">
              {navigation.map(n => (
                <li key={n[0].toLowerCase()}>
                  <Link to={n[1]}>{n[0]}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="level-right">
            <div className="navbar-menu">
              <a href="https://app.amplisend.com/login" target="_blank" rel="noopener noreferrer" className="btn text-primary">Login</a>
              <a href="https://app.amplisend.com/register" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-round">Register</a>
            </div>
            <div className="mobile-menu" style={{ display: menuDisplay ? 'inherit' : 'none' }}>
              <ul>
                {navigation.map(n => (
                  <li key={n[0].toLowerCase()} onClick={() => redirectTo(n[1])}>
                    {n[0]}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex">
              <div className="menu-toggle-icon" onClick={toggleMenu}>
                <div className="menu-toggle">
                  <div className="menu">
                    <input type="checkbox" />
                    <div className="line-menu"></div>
                    <div className="line-menu"></div>
                    <div className="line-menu"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <DefaultHeader
        title={state.title}
        subTitle={state.subTitle}
        header={state.header}
        subHeader={state.subHeader}
        icon={state.icon}
        link={state.link}
        linkText={state.linkText}
        homepage={state.homepage}
      />
    </header>
  )
}

export default Header;
