import React, { useEffect } from 'react';
import YouTube from 'react-youtube-embed';
import IosRedo from 'react-ionicons/lib/IosRedo';

const Developers = () => {
  useEffect(() => {
    document.title = 'Amplisend - Developers';
  });

  return (
    <section className="section is-sm section-contact">
      <img className="section-particle top-0" src="./images/others/particle.svg" alt="" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className=" items-contact">
              <div className="section-head">
                <h2 className="section-title ">Developers</h2>
                <p className="section-desc mb-0">
                  We love developers, that's why we have ever eveloving APIs
                  which allow you to build your own custom workflows.<br />
                </p>
                <br /><br />
                <a href="https://developers.amplisend.com" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-round">
                  <span>
                    API Documentation
                    {' '}
                    <IosRedo color="#fff" />
                  </span>
                </a>
              </div>
              <div className="col-lg-12">
                <div className="contact-item">
                  <h6>Secure</h6>
                  <p className="contact-item-info">All our APIs are secured with API keys &amp; permission controls.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-item">
                  <h6>Webhook Callbacks</h6>
                  <p className="contact-item-info">
                    Don't poll for inbound SMS or delivery receipts. We'll push messages and receipts
                  direct to your web server.<br />
                  All pushes are digitally signed to prevent forgery.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-item">
                  <h6>Free testing</h6>
                  <p className="contact-item-info">
                    Need to mock send and receive notifications from Amplisend? Our API has full support to
                    make testing all scenarios easily.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-form">
              <div className="form-group">
              <YouTube id="1VgVJpVx9bc" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Developers;
