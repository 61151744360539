import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PaymentItems from './PaymentItems';
import PaymentItem from './PaymentItem';

const PaymentOption = ({title, price, currency, description, items, position}) => (
  <div className="col-md-4">
    <div className={clsx(['plan-item', 'has-style1', position])}>
      <div className="plan-head">
        <h4 className="plan-title">{title}</h4>
        <div className="flex center">
          <div className="plan-price">{price}</div>
          <span className="price-var ">{currency}</span>
        </div>
        <div className="flex center">
          <span className="price-var">{description}</span>
        </div>
        <div className="flex center mt-4">
          <a href="https://app.amplisend.com/register" className={clsx(['btn', 'btn-round', position === 'is-active' ? 'btn-primary' : 'btn-dark'])}>
            Register for free
          </a>
        </div>
      </div>
      <div className="plan-body">
        <ul className="list-items">
          {items
            .filter(item => item.substr(0, 1) !== '*')
            .map((item, i) => (
            <PaymentItem key={i}>{item}</PaymentItem>
          ))}
          <PaymentItems section={title.toLowerCase().replace(/\s+/, '')} />
          {position === 'is-active' && (
            <>
            <hr />
              {items
                .filter(item => item.substr(0, 1) === '*')
                .map((item, i) => (
                <PaymentItem key={i} icon={false}>{item.substr(1)}</PaymentItem>
              ))}
            </>
          )}
        </ul>
      </div>
    </div>
  </div>
);

PaymentOption.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  position: PropTypes.oneOf(['is-active', 'in-left', 'in-right']).isRequired,
};

export default PaymentOption;
