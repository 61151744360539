import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Header from './Header';
import Home from './Home';
import About from './About';
import Pricing from './Pricing';
import Developers from './Developers';
import Contact from './Contact';
import TermsAndConditions from './TermsAndConditions';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from './Footer';

const { REACT_APP_GTM, REACT_APP_GTM_AUTH, REACT_APP_GTM_PREVIEW } = process.env;

const tagManagerArgs = {
    gtmId: REACT_APP_GTM,
    auth: REACT_APP_GTM_AUTH,
    preview: REACT_APP_GTM_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

const App = () => (
  <>
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/pricing" exact component={Pricing} />
        <Route path="/developers" exact component={Developers} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
        <Route path="/terms-of-use" exact component={TermsOfUse} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      </Switch>
      <Footer />
    </Router>
  </>
);

export default App;
