import React, { useEffect } from 'react';

const Item = ({ title, children }) => (
  <p className="color-primary" style={{ lineHeight: '2em' }}>
    <strong>{title}</strong><br />
    {children}
  </p>
);

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Amplisend - Terms and Conditions';
  });

  return (
    <section className="section sm section-blog2 mt-6">
      <div className="container">
        <div className="content-post">
          <h1>Terms &amp; Conditions</h1>
          <hr />
          <Item title="1. Definitions">
            'Account' means the Customer's subscription for the Service including the business and login information and Password by which the Customer receives the Service.<br />
            'Charges' means the amount payable by the Customer for the Service.<br />
            'Messages' means a short text message sent to a mobile phone using the Service.<br />
            'Password' means the combination of the Customer's account details and a password that will be provided by Amplisend (Edgility) to the Customer after the Customer has accepted these Terms of Service.<br />
            'Pricing Structure' means the schedule, which identifies the Charges applicable for the Service or any part of the Service, as amended from time to time.<br />
            'Terms of Service' means the terms and conditions contained on these pages as amended from time to time including any attached schedules, the Amplisend (Edgility) copyright and disclaimer provisions and the Pricing Structure.<br />
            'Service' means the services outlined in clause 2.<br />
            'Site' means the website identified by the domain name including Edgility and/or Amplisend, the pre-determined SMS web site.<br />
            'Edgility', 'FONELiNC Pty Ltd' or 'Amplisend' means FONELiNC Pty Ltd trading as Edgility at Suite 5.16, 55 Miller Street PYRMONT NSW 2009 (ACN 092 285 955).<br />
            'the Customer' means the signatory, being the subscriber to the Service commencing upon signing the service agreement.<br />
          </Item>
          <Item title="2. The Service">
            2.1 The Service will, amongst other things, enable the Customer to send Messages to Australian and New Zealand mobile phone numbers on the GSM network via the Amplisend or Edgility platform on a non-transferable basis during the term of the agreement.<br />
            2.2 The Amplisend platform, for the purpose of providing the Service can be accessed via:<br />
            (a) the Edgility WebSMS Site; or<br />
            (b) the Amplisend WebSMS Site; or<br />
            (c) the Customer's desktop E-mail application; or<br />
            (d) a FTP (file transfer protocol) process.
          </Item>
          <Item title="3. Customer obligations">
            3.1 The Customer must:<br />
            (a) Complete the registration process, read and accept these Terms of Service, as amended and updated from time to time;<br />
            (b) Correctly and accurately enter Customer business details as part of the registration process;<br />
            (c) Abide by the Terms of Service and all applicable laws and not place Edgility in breach of any laws;<br />
            (d) Pay Edgility all fees and charges incurred from use of the Service, and all applicable government taxes, duties, imposts or levies;<br />
            (e) Provide Edgility with all information and co-operation required in relation to the Service;<br />
            (f) Follow Edgility's instructions regarding the use of the Service;<br />
            (g) Have prior written permission from Edgility to 'on-sell' the Service to other Customers, users or businesses.<br />
            3.2 The Customer acknowledges and accepts responsibility for the content of the Messages sent via the Service. The Customer warrants not to transmit any Message which includes any unlawful, harassing, libellous, abusive, threatening, harmful, obscene, 'spam', 'junk mail' or otherwise objectionable material of any kind, or any material that violates the rights of a third party, including but not limited to the intellectual property rights of that party. The Customer agrees that Edgility will not be liable or responsible for the content and nature of the Messages.<br />
            3.3 The Customer can only use the Service for legitimate business purposes to communicate with employees, existing business relationships and existing customers. When the Service is used for marketing and promotional purposes, the Customer must be able to present evidence of permission from the destination mobile phone holder to receive messages on their mobile phone. Edgility will provide any expertise and advice in obtaining such permissions if the Customer requires such assistance.<br />
          </Item>
          <Item title="4. Edgility's provision of the Service">
            4.1 Edgility or Amplisend is not responsible or liable for any part of the promotions, advertising or Messages between the Customer and any third party in connection with the Service.<br />
            4.2 Edgility and Amplisend reserves the right to modify or suspend the Service with reasonable notice to the Customer. The Customer acknowledges and accepts that Edgility will not be liable to the Customer or any third-party for any modification, suspension or discontinuance of the Service.
          </Item>
          <Item title="5. Privacy">
            5.1 The Customer must keep the Password and Account details confidential, and assumes entire responsibility for any use of the Services or any other acts that occur under or in relation to the Customer's Password or Account as a result of failure to keep Password and Account details secret. The Customer agrees to immediately notify Edgility of any disclosure or unauthorized use of Password or Account or any other breach of security.<br />
            5.2 Edgility collects information, including the personal information provided by the Customer to Edgility for purposes including:<br />
            (a) to register the Customer for the Service and provide the Customer with the Service;<br />
            (b) to provide a more customised service as part of the Service.<br />
            5.3 Edgility will not disclose Customer user information to anyone except in accordance with these Terms of Service, as authorised by the Customer or as required by law. Edgility may, however, disclose to third parties aggregate data, provided that no individual is identifiable in such data.<br />
          </Item>
          <Item title="6. Payment">
            6.1 Edgility will prepare and issue, a GST compliant invoice for products and services at the end of each calendar month. This invoice will provide a detailed summary of charges for that particular month. This invoice is payable by the Customer within 14 days of the close of the previous billing month.<br />
            6.2 The Charges will be determined in accordance with the Pricing Structure as amended from time to time. Edgility will advise the Customer 30 days prior (in writing) of any changes to the pricing structure<br />
            6.3 If the Customer has not prepaid for the Service, the Customer acknowledges and accepts liability and agrees to pay all outstanding Charges incurred prior to the termination of the Customer's Account. The termination of the Account will not release the Customer from any liability to pay outstanding Charges.<br />
            6.4 If the Customer has prepaid for the Service and elects to discontinue the Service for any reason other than a breach of Service Level Agreement by Edgility, the Customer acknowledges that any unused purchases, fees or messages are not refundable under any circumstances. This includes any prepaid annual packages or plans.
            6.5 Monthly and Annual billing will commence from the first calendar day of the month that the account is activated regardless of the date of account provisioning. No pro-rata reduction or consideration will be applied to any Monthly or Annual packages that have commenced the Service after the first calendar day of the month.<br />
          </Item>
          <Item title="7. Changes to the Terms of Service">
            7.1 Edgility may change these Terms of Service and/or Pricing Structure at any time and will notify the Customer of the changes by posting the new Terms of Service and/or Pricing Structure on the Site<br />
            7.2 Edgility undertakes to advise the Customer in writing of any changes to these Terms of Service. Any changes other than message market rates must be mutually agreed upon by Edgility and the Customer
          </Item>
          <Item title="8. Termination">
            8.1 If either Edgility or the Customer have deemed to breach any part of these Terms and Conditions, either party may apply for termination of its Account after serving 30 days' notice in writing to the respective party. The Customer acknowledges and accepts liability for paying all outstanding Charges due to Edgility under this agreement up to the date of Termination.<br />
            8.2 Edgility may terminate the Customer's Account immediately if:<br />
            (a) In Edgility's opinion the Customer has violated these Terms of Service
          </Item>
          <Item title="9. Copyright Notice">
            9.1 The Edgility and/or Amplisend platforms, the Site and its contents are subject to copyright under the laws of Australia and, through international treaties, other countries. The copyright is owned by Edgility. Third parties may own some of the content on the Site (such as their own company logo).<br />
            9.2 The Customer accepts that all contents of the Service are protected by copyright, trademarks, or other proprietary rights and laws. The Customer must not reproduce, transmit (including broadcast), adapt or otherwise exercise the copyright in the whole or any part of the Site for any other purpose except as expressly permitted by statute or with Edgility's written consent.
          </Item>
          <Item title="10. Disclaimers, Indemnities and Limitation of Liability">
            10.1 The use of the Service is at the Customer's sole risk. The Service is provided on an "as is" and "as available" basis. Edgility or Amplisend does not warrant that the Service will meet the Customer's requirements, nor that the Service will be uninterrupted, timely, secure, or error free; nor does Edgility warrant the results that may be obtained from the use of the Service.<br />
            10.2 Edgility will charge the Customer for use of the Service at the agreed rate per message per intended recipient, irrespective of whether the message is successfully delivered to, or received by, the intended recipient. Messages sent to a group will be treated as individual messages sent to each of the group's members and charged accordingly.<br />
            10.3 Albeit that the Site may be accessed from countries and jurisdictions outside Australia, Edgility makes no representation that the content of the Site complies with the laws (including intellectual property laws) of any country or jurisdiction outside Australia.<br />
            10.4 Edgility will endeavour to use due care and skill in the storage and transmission of message content and will do so in accordance with its privacy and security procedures outlined in clause 5. However, due to the nature of the media and modes of communication used by the Service, and factors outside of reasonable control, Edgility does not guarantee that the Information will not be received or handled other than intended.<br />
            10.5 To the extent permitted by law, Edgility and its employees, agents and consultants exclude all liability for any loss or damage (including indirect, special or consequential loss or damage) arising from the use of, or reliance on, the Information provided it is not caused by any negligent act or omission. If any law prohibits the exclusion of such liability, Edgility's liability under these Terms of Service or otherwise in connection to provision of the Services is limited to the extent permitted by law.<br />
            10.6 The Customer releases and indemnifies Edgility, Amplisend its officers and employees from liability for any direct, indirect, incidental, special or consequential damages whatsoever, including but not limited to liability for any damages, resulting from unauthorized access by third parties to the data or Information and any claim or demand made by any third party due to or arising out of the Customer's use of the Service, violation of the Terms of Service or violation of any rights of another, provided it is not caused by the negligence of Edgility or its officers or employees.<br />
            10.8 Any claim or cause of action arising out of or related to use of the Service or these Terms of Service must be filed within 6 months after such claim or cause of action arose or be forever barred.
          </Item>
          <Item title="11. General">
            11.1 The laws of the State of NSW, Australia, govern these Terms of Service. Both the Customer and Edgility submit to the nonexclusive jurisdiction of the Courts of NSW, Australia.<br />
            11.2 Except as otherwise expressly agreed, all notices from the Customer to Edgility must be sent by e-mail to Edgility through that part of the Site expressly designed for the purpose.<br />
            11.4 If any provision of these Terms of Service is found to be invalid by any court having competent jurisdiction, the invalidity of such a provision will not affect the validity of the remaining provisions of these Terms of Service.
          </Item>
          <Item title="12. Acceptance of Terms of Service">
            12.1 By acknowledging that the Customer has read and understands these Terms of Service and completing the registration process for the Service, the Customer is agreeing to be bound by these Terms of Service and that these Terms of Service contain the full and entire agreement covering the Customer's use of the Service
          </Item>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditions;
