import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footers = [
    [
      ['About', '/about'],
      ['Pricing', '/pricing'],
      ['Contact', '/contact'],
    ],
    [
      ['Developers', '/developers'],
    ],
    [
      ['Terms and Conditions', '/terms-and-conditions'],
      ['Terms of Use', '/terms-of-use'],
      ['Privacy Policy', '/privacy-policy'],
    ]
  ]

  return (
    <footer className="footer has-style2">
      <div className="footer-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-desc">
                <div className="logo">
                  <img src="./logo512.png" alt="Amplisend" />
                </div>
                <p>
                  Amplisend Pty Ltd
                </p>
              </div>
            </div>
            {footers.map((f, fi) => (
              <div className="col-lg-3 col-sm-4" key={fi}>
                <ul className="list-items">
                  {f.map((footer, i) => (
                    <li key={`${fi}-${i}`}>
                      <Link to={footer[1]}>{footer[0]}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <p className="copyright text-center text-copyright">
            Copyright Amplisend - {currentYear}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
