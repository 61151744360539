import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  useEffect(() => {
    document.title = 'Amplisend - SMS Communication Experts';
  });

  const features = [
    ['Easy to use API', 'Send SMS from your own applications with minimal effort.'],
    ['Automatic optouts', 'Stay anti-spam compliant with automatic opt-outs.'],
    ['Free notifications', 'Receive free replies and delivery notifications with our automatic web push.'],
  ];

  return (
  <>
    <section className="section is-sm section-feautures2">
      <div className="container">
        <div className="section-head d-flex justify-content-start align-items-center">
          <div className="row">
            <div className="col-md-5 col-lg-4">
              <h2 className="section-title text-white">Features</h2>
            </div>
            <div className="col-md-7 col-lg-8">
              <p className="text-white">
                Amplisend offers everything you need to send, receive, track, and report on your transactional and marketing SMS.
              </p>
            </div>
          </div>
        </div>
        <div className="boxes">
          <div className="row min-30 flex center">
            {features.map((feature, i) => (
            <div className="col-lg-3 col-md-6" key={i}>
              <div className="box has-shadow">
                <div className="box-particles">
                  <img src="./images/others/box-particles.svg" alt="" />
                </div>
                <div className="box-num">{i + 1}</div>
                <h3 className="box-title">{feature[0]}</h3>
                <p className="box-desc">{feature[1]}</p>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </section>

    <section className="section is-sm section-about">
      <div className="container">
        <div className="row flex vcenter">
          <div className="col-lg-6">
            <div className="section-head">
              <h5 className="section-subtitle">Keep Connected</h5>
              <h2 className="section-title">
                Stay connected with your customers and team.
              </h2>
              <p className="section-desc">
                Connecting with Amplisend allows you to send transactional or bulk marketing campaigns to your customers, team or anyone inbetween. <br />
                Contact Amplisend to discuss how SMS can be used to boost engagement, security, and revenue of your project.
              </p>
              <Link to="/contact-us" className="btn btn-primary btn-round">Contact Amplisend</Link>
            </div>
          </div>
          <div className="col-lg-6">
            <img className="about-img" src="./images/bg/about-illustration.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
    {/*
    <section className="section is-sm section-testimonial section-grey  overflow-hidden">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="section-head mb-lg-0">
              <h5 className="section-subtitle"> Testimonials</h5>
              <h2 className="section-title">
                we help readers and writers everyday
                <span className="text-primary">.</span>
              </h2>
              <h3 className="display-4 mb-2 text-primary mb-0">
                4.5
                <span className="text-primary">.</span>
              </h3>
              <p className=" section-desc  max-30 mt-1 mb-1">
                orem ipsum dolor sit amet, consectetur adipisicing
                elit, do
                eius
                mod
                tempor incididun
              </p>
              <img className="stars" src="./images/others/stars.svg" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="client-wrap is-white">
              <div className="client-img">
                <img src="./images/others/client-img.svg" alt="" />
              </div>
              <p className="client-quote">
                Share your beautiful travel stories with bold imagery in this
                contemporary personal magazine. Featured images use the new shape dividers for extra style.
              </p>
              <div className="flex">
                <strong className=" client-name">Ayoub fennouni</strong>
                <p className="client-position">CEO</p>
              </div>
            </div>
            <img className="section-shape2" src="./images/others/testimonials-white.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
    */}
  </>
  )
};

export default Home;
