import React, { useEffect } from 'react';
import clsx from 'clsx';

const About = () => {
  useEffect(() => {
    document.title = 'Amplisend - About';
  });

  const stats = [
    ['Millions', 'sent & received SMS'],
    ['Experts', 'in SMS'],
    ['Easy', 'integration'],
    ['Unlimited', 'possibilities'],
  ];

  const useCases = [
    ['Marketing', 'Send large campaigns directly to your customers mobiles within seconds.'],
    ['Security', 'Two factor or one-time-password authentication messages sent instantly.'],
    ['Transactional', 'Notify a customer of a change to their order or status.'],
    ['Verifiction', 'Send an SMS to a new user to verify their mobile number.'],
    ['Digital Receipts', 'Save paper and send customers receipts direct to their phone.'],
    ['Notifications', 'Alert your team of an outage or issue instantly and affordably.'],
  ];

  return (
    <>
      <section className="section section-about section-counter">
        <div className="container">
          <div className="row counters flex center">
            {stats.map((stat, s) => (
              <div className="col-lg-3 col-md-4 col-6" key={s}>
                <div className="counter-item">
                  <h5 className="counter-number">{stat[0]}</h5>
                  <p className="counter-name">{stat[1]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="section is-sm section-about">
        <img className="section-particle top-0" src="./images/others/particle.svg" alt="" />
        <div className="container">
          <div className="row flex vcenter">
            <div className="col-lg-6">
              <img className="about-img" src="./images/bg/about-illustration2.svg" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="section-head">
                <h5 className="section-subtitle ">
                  Our Platform
                </h5>
                <h2 className="section-title ">Boost your business communications</h2>
                <p className="section-desc">
                  Amplisend's platform allows you to send and receive SMS with ease.
                </p>
                <p className="section-desc">
                  Our easy to use APIs and clear documentation means you can start sending SMS quickly and reliably.
                </p>
                <p className="section-desc">
                  Within a few minutes you can boost your communications and start contacting your customers, staff, or prospects directly from your own application.
                </p>
                <a href="https://app.amplisend.com/register" className="btn btn-primary btn-round">Register a free account now</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-sm section-grey">
        <div className="container">
          <div className="lines">
            <img src="./images/others/lines.svg" alt="" />
          </div>
          <div className="section-head flex between vcenter wrap">
            <h2 className="section-title ">SMS has limitless use cases</h2>
          </div>
          <div className="boxes">
            <div className="row min-30">
              {useCases.map((useCase, i) => (
                <div className="col-lg-4 col-md-6" key={i}>
                  <div className={clsx(['box', 'has-left-icon', i % 2 ? 'has-shadow' : 'has-secondary-bg'])}>
                    <div className="box-particles2">
                      <img src="./images/others/box-particle-2.svg" alt="" />
                    </div>
                    <div className="row">
                      <div className="col">
                        <h3 className="box-title">{useCase[0]}</h3>
                        <p className="box-desc">{useCase[1]}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
