import React, { useEffect } from 'react';

const Item = ({ title, children }) => (
  <p className="color-primary" style={{ lineHeight: '2em' }}>
    {title && (<>
      <strong>{title}</strong>
      <br />
    </>)}
    {children}
  </p>
);

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'Amplisend - Terms of Use';
  });

  return (
    <section className="section sm section-blog2 mt-6">
      <div className="container">
        <div className="content-post">
          <h1>Privacy Policy</h1>
          <hr />
          <Item title="Information Collection and Use">
            Edgility is the sole owner of the information collected on this site. We will not sell, share, or rent this information to others in ways
            different from what is disclosed in this statement. Edgility collects information from our users at several different points on our website.
          </Item>
          <Item title="Registration">
            In order to use this website, a user must first complete the registration form. During registration a user is required to give their contact
            information (such as name and email address). This information is used to contact the user about the services on our site for which
            they have expressed interest.
          </Item>
          <Item title="Cookies">
            A cookie is a piece of data stored on the user's hard drive containing information about the user. Usage of a cookie is in no way
            linked to any personally identifiable information while on our site. Once the user closes their browser, the cookie simply terminates. For
            instance, by setting a cookie on our site, the user would not have to log in a password more than once, thereby saving time while on
            our site. Edgility's website will log people out of a secure session after 2 hours of inactivity by a user.
          </Item>
          <Item title="Log Files">
            We use IP addresses to analyse trends, administer the site, track user's movement(s), and gather broad demographic information for
            aggregate use. IP addresses are not linked to personally identifiable information.
          </Item>
          <Item title="Sharing">
            We do not share aggregated demographic information with partners or advertisers.
          </Item>
          <Item title="Links">
            This web site contains links to other sites. Please be aware that we, Edgility, are not responsible for the privacy practices of such other
            sites. We encourage our users to be aware when they leave our site and to read the privacy statements of each and every web site
            that collects personally identifiable information. This privacy statement applies solely to information collected by this Web site
            (www.edgility.com.au).
          </Item>
          <Item title="Surveys &amp; Contests">
            From time-to-time our site requests information from users via surveys or contests. Participation in these surveys or contests is
            completely voluntary and the user therefore has a choice whether or not to disclose this information. Information requested may
            include contact information (such as name and address) and demographic information (such as postcode, age level). Contact
            information will be used to notify the winners and award prizes. Survey information will be used for purposes of monitoring or
            improving the use and satisfaction of this site.
          </Item>
          <Item title="Security">
            This website takes every precaution to protect our users' information. When users submit sensitive information via the website, your
            information is protected both online and off-line.<br /><br />
            When our registration/order form asks users to enter sensitive information (such as credit card number), that information is encrypted
            and is protected with industry-standard strong encryption - SSL. While on a secure page, such as our order form, the lock icon on the
            bottom of Web browsers such as Netscape Navigator and Microsoft Internet Explorer becomes locked, as opposed to un-locked, or
            open, when you are just 'surfing'.<br /><br />
            While we use SSL encryption to protect sensitive information online, we also do everything in our power to protect user-information
            off-line. All of our users' information, not just the sensitive information mentioned above, is restricted in our offices. Only employees
            who need the information to perform a specific job (for example, our billing clerk or a customer service representative) are granted
            access to personally identifiable information. Our employees must use password-protected screen-savers when they leave their desk.
            When they return, they must re-enter their password to re-gain access to your information. Furthermore, ALL employees are kept upto-date on our security and privacy practices. Every quarter, as well as any time new policies are added, our employees are notified
            and/or reminded about the importance we place on privacy and what they can do to ensure our customers' information is
            protected. Finally, the servers that we store personally identifiable information on are kept in a secure environment, behind a locked
            door.<br /><br />
            Users of the Edgility Messaging Service must realise that although Edgility employs high levels of security, no data transmission over the
            internet can be 100% safe.<br /><br />
            Therefore, use of Edgility's Messaging is at the client's own risk.<br /><br />
            Furthermore, users must be aware that sending messages with Edgility will have a similar level of security and reliability as that of
            Mobile Phone Networks.<br /><br />
            Edgility, its employees and contractors will not be held liable for any loss or damage whatsoever, incurred through the use of Edgility
            SMS Messaging, including loss of information due to carrier failure, network failure or negligence on the part of Edgility, its employees
            or contractors.<br /><br />
            Please refer to our Terms and Conditions and Disclaimer for more information.
          </Item>
          <Item title="Ask Us About Your Privacy">
            If you have any questions about this privacy statement, the practices of this site, or your dealings with this web site, you can contact
            us at <a href="mailto:info@edgility.com.au">info@edgility.com.au</a>
          </Item>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
