import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MdArrowRoundForward from 'react-ionicons/lib/MdArrowRoundForward';

export const DefaultHeader = ({ title, subTitle, header, subHeader, icon, link, linkText, homepage }) => (
  homepage && (
    <>
      <div className="container">
        <div className={clsx('header-wrap', !homepage ? 'section' : null)}>
          <div className={homepage ? 'row align-items-center' : null }>
            <div className={homepage ? 'col-lg-6' : null }>
              <h2 className="header-title">{title}</h2>
              {subTitle && (
                <p className={homepage ? 'header-desc' : 'section-desc is-center' }>{subTitle}</p>
              )}
              {link && (
                <a href={link} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-round">
                  <>
                  {linkText}
                  {icon && <MdArrowRoundForward color="#fff" />}
                </>
              </a>
              )}
              <div className="d-flex justify-content-center">
                {header && (
                  <div className="mr-4">
                    {header}
                  </div>
                )}
                {subHeader && link && (
                  <a href={link} className="text-primary">
                    {icon && <MdArrowRoundForward color="#fff" />}
                    {subHeader}
                  </a>
                )}
              </div>
            </div>
            {homepage && (
              <div className="col-lg-6">
                <div className="header-img">
                  <img src="./images/others/grad-illustration.svg" alt="" />
                </div>
              </div>
            )}
          </div>
          {!homepage && (<img className="shape" src="./images/others/shape2.svg" alt="" />)}
        </div>
      </div>
      {homepage && (
        <div className="hero-particles particle-container">
          <div className="particle particle-6" />
        </div>
      )}
    </>
  )
);

export default DefaultHeader;

DefaultHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  icon: PropTypes.bool,
  link: PropTypes.string,
  linkText: PropTypes.string,
  homepage: PropTypes.bool,
};

DefaultHeader.defaultProps = {
  title: null,
  subTitle: null,
  header: null,
  subHeader: null,
  icon: false,
  link: null,
  linkText: null,
  homepage: false,
};
