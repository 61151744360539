import React from 'react';
import PropTypes from 'prop-types';

import PaymentItem from './PaymentItem';

const PaymentItems = ({ section }) => (
  <>
    {[
      'Free inbound SMS',
      'Free delivery receipts',
      'Free opt out management',
      'Free reporting',
      'Free access to the Amplisend platform',
    ].map((text, i) => (
      <PaymentItem key={`${section.toLowerCase()}-${i}`}>{text}</PaymentItem>
    ))}
  </>
);

PaymentItems.propTypes = {
  section: PropTypes.string.isRequired,
};

export default PaymentItems;
