import React, { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    document.title = 'Amplisend - Contact';
  });

  return (
    <section className="section is-sm section-contact">
      <img className="section-particle top-0" src="./images/others/particle.svg" alt="" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="items-contact">
              <div className="section-head">
                <h2 className="section-title ">Contact</h2>
                <p className="section-desc mb-0">
                  Want to talk about SMS? We're here to help.<br />
                  You can call us between 9-5 AEST Monday to Friday, or email us any time.<br />
                  If you're more old fashion send us a letter.
                </p>
              </div>
              <div className="col-lg-12">
                <div className="contact-item">
                  <h6>Number</h6>
                  <p className="contact-item-info">1300 764 451</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-item">
                  <h6>Address</h6>
                  <p className="contact-item-info">sms@amplisend.com</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-item">
                  <h6>Address</h6>
                  <p className="contact-item-info">
                    Suite 5.16<br />
                    55 Miller Street<br />
                    Pyrmont<br />
                    NSW, Australia
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <form className="contact-form">
              <div className="form-group">
                <label>Name</label>
                <input name="name" className="form-control has-style1" id="name" type="text" />
              </div>
              <div className="form-group">
                <label>Email or phone number</label>
                <input name="email" className="form-control has-style1" id="how" type="text" />
              </div>
              <label>How can we help?</label>
              <textarea name="textarea" className="textarea has-style1" />
              <div className="form-group">
                <button className="btn btn-primary btn-round">
                  <span> Send Message </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
