import React from 'react';
import PropTypes from 'prop-types';
import IosCheckmark from 'react-ionicons/lib/IosCheckmark';

const PaymentItem = ({ icon, children }) => (
  <li className="item">
    {icon && (
      <>
        <IosCheckmark />
        {children}
      </>
    )}
    {icon === false && (
      <small>{children}</small>
    )}
  </li>
);

PaymentItem.propTypes = {
  icon: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

PaymentItem.defaultProps = {
  icon: true,
};

export default PaymentItem;
