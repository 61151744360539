import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import PaymentOption from './inc/Pricing/PaymentOption';
import FAQItem from './inc/Pricing/FAQ';

const Pricing = () => {
  const [state, setState] = useState({
    selected: 'AU',
  });

  useEffect(() => {
    document.title = 'Amplisend - Pricing';
  });

  const fixed = [50, 5000];

  const countries = {
    AU: {
      name: 'Australia',
      prePaid: 6.5,
      payg: 5,
    },
    UK: {
      name: 'United Kingdom',
      prePaid: 6.5,
      payg: 5,
    },
    NZ: {
      name: 'New Zealand',
      prePaid: 7,
      payg: 6.5,
    },
    US: {
      name: 'United States of America',
      prePaid: 3,
      payg: 2.5,
    },
  };

  const formatPrice = price => {
    return new Intl.NumberFormat('en-AU').format(Math.ceil(price));
  }

  const handleChange = e => {
    e.preventDefault();

    setState({
      selected: e.target.value,
    });
  }

  return (
    <>
      <section className="section is-sm section-plans">
        <img className="section-particle top-0" src="./images/others/particle.svg" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-2 hidden-sm">&nbsp;</div>
            <div className="col-lg-6 col-md-8 col-sm-12">
              <div className="section">
                <div className="items-contact">
                  <div className="contact-item">
                    <h6>What country are you sending messages to?</h6>
                    <select className="form-control has-style1" onChange={handleChange}>
                      {Object.keys(countries).map((key, c) => (
                        <option value={key} key={c}>{countries[key].name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row flex vcenter">
            <PaymentOption
              title="Pay as you go"
              price={formatPrice(fixed[0])}
              currency="$"
              description="per month"
              items={[
                `${formatPrice(50 / (countries[state.selected].payg / 100))} included SMS per month`,
                `${countries[state.selected].payg}c per SMS thereafter`,
                'Free virtual number',
              ]}
              position="in-left"
            />
            <PaymentOption
              title="Pre Paid"
              price={countries[state.selected].prePaid.toString()}
              currency="c"
              description="per outbound SMS"
              items={[
                `${countries[state.selected].prePaid}c pre paid outbound SMS`,
                '12 month credit validity',
                '* All prices in AUD & include of GST',
              ]}
              position="is-active"
            />
            <PaymentOption
              title="Enterprise"
              price={formatPrice(fixed[1])}
              currency="$"
              description="per month"
              items={[
                `${formatPrice(fixed[1] / (countries[state.selected].payg / 100))} included SMS per month`,
                'Custom rate SMS thereafter',
                'Free virtual numbers',
              ]}
              position="in-right"
            />
          </div>
        </div>
      </section>
      <div className="container">
        <div className="section is-sm">
          <section className="section is-sm section-grey">
            <div className="section-head">
              <h2 className="section-title is-center">Frequently asked questions</h2>
            </div>
            <div className="row">
              <FAQItem title="How does the pre paid option work?">
                <>
                  All SMS credits are purchased up front. Once you fall below your notification threshold
                  you'll receive an email alerting you of your low balance. You can then login to Amplisend at
                  any time and purchase more credits.<br /><br />
                  All credits last 12 months from the original date of purchase.
                </>
              </FAQItem>
              <FAQItem title="I need help, do you offer support?">
                <>
                  Amplisend offers free support, simply
                  {' '}
                  <Link to="/contact">contact us</Link>
                  {' '}
                  and we can offer both technical and non-technical support.
                </>
              </FAQItem>
              <FAQItem title="I'm unsure what my use-case is for SMS">
                <>
                  Need to talk to someone about how you can use SMS to increase performance of your business?
                  No problem!
                  {' '}
                  <Link to="/contact">Contact us</Link>
                  {' '} 
                  and we'll take the time to understand your business
                  and identify potential use-cases for SMS within your workflows.
                </>
              </FAQItem>
              <FAQItem title="Do I qualify for a pay as you go plan?">
                <>
                  Amplisend offers PAYG plans to customers that pass a background check in Australia and New Zealand.<br />
                  All plans are have a minimum commitment of 6 months. Plans can be upgraded without penalty during their commitment period.
                </>
              </FAQItem>
              <FAQItem title="I send more than 100,000 SMS per month">
                <>
                  Great, we can offer you a custom price based on your committed usage.
                  {' '}
                  <Link to="/contact">Contact us</Link>
                  {' '}
                  and we'll work out a custom price.
                </>
              </FAQItem>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Pricing;
